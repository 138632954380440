import React, { Fragment, useState, useEffect, Component } from "react"
import StepWizard from "react-step-wizard"

import { Form, Button, Grid, Message, List } from "semantic-ui-react"

import Nav from "../components/Wizard2/nav"
import Plugs from "../components/Wizard2/Plugs"
import NavV2 from "../components/NavV2"

import GlobalFonts from "../font/fonts"

import white_logo_text from "../images/white_logo_text.png"
import black_logo_text from "../images/black_logo_text.png"

import topbackground from "../assets/TopPolygon1.svg"
import topbackground2 from "../assets/TopPolygon2.svg"

import styled from "styled-components"

import Helmet from "react-helmet"

import {
  EmailIcon,
  FacebookIcon,
  FacebookShareButton,
  FacebookMessengerIcon,
  LinkedinIcon,
  LivejournalIcon,
  PinterestIcon,
  LinkedinShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share"
import SEO from "../components/seo"

/**
 * A basic demonstration of how to use the step wizard
 */
var obj = {}
var email = ""

const Wizard = () => {
  
  const [state, updateState] = useState({
    form: {},
    transitions: {
      enterRight: `${"animation-duration: .8192s"} ${"animation-name: enterRight"}`,
      enterLeft: `${"animation-duration: .8192s"} ${"animation-name: enterLeft"}`,
      exitRight: `${"animation-duration: .8192s"} ${"animation-name: exitRight"}`,
      exitLeft: `${"animation-duration: .8192s"} ${"animation-name: exitLeft"}`,
      intro: `${"animation-duration: .8192s"} ${"animation-name: intro"}`,
    },
    // demo: true, // uncomment to see more
  })

  const updateForm = (key, value) => {
    const { form } = state

    form[key] = value
    updateState({
      ...state,
      form,
    })
  }

  // Do something on step change
  const onStepChange = stats => {

    var axios = require("axios")
    var data = JSON.stringify({
      email: obj["email"],
      dataFields: obj,
    })

    console.log("data")
    console.log(data)

    // frontend iterable
    var config = {
      method: "post",
      url: "https://api.iterable.com/api/users/update",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "dd373517425447ca8ab5d993282d17ab",
      },
      data: data,
    }

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data))
        // setEmailSent("We'll send you info!")
      })
      .catch(function (error) {
        console.log(error)
      })

  }

  const setInstance = SW =>
    updateState({
      ...state,
      SW,
    })

  const { SW, demo } = state

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Cloud Castles Vacation Homes</title>
        <meta
          name="google-site-verification"
          content="lRDxE-lZUVoBRi5yV2AHoVkcaALQME9xGG-XwQYIbcU"
        />{" "}
        <meta
          name="keywords"
          content="Vacation home Real estate investing, is real estate investing worth it, is real estate investing a business,how to start real estate investing"
        />
        <meta
          name="description"
          content="Allowing Anyone To Own Anywhere. Your go to Vacation home Real estate investing company."
        />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-10871406798"
        ></script>
        <script type="application/ld+json">{`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('config', 'AW-10871406798');
  `}</script>
      </Helmet>
      <Body>
        <GlobalFonts />

        <Hero>
          <Logo src={black_logo_text}></Logo>

          <div >
            <div className="row">
              <div
                className={`col-12 col-sm-6 offset-sm-3 ${"min-height: 400px !important;"}`}
              >
                <StepWizard
                  onStepChange={onStepChange}
                  isHashEnabled
                  // transitions={state.transitions} // comment out for default transitions
                  // nav={<Nav />}
                  instance={setInstance}
                >
                  <First hashKey={"FirstStep"} update={updateForm} />
                  <Second />
                  <Box />
                  <Progress stepName="progress" />
                  {null /* will be ignored */}
                  <Last hashKey={"TheEnd!"} />
                </StepWizard>
              </div>
            </div>
          </div>
          {demo && SW && <InstanceDemo SW={SW} />}
        </Hero>
      </Body>
    </>
  )
}


export default Wizard

/** Demo of using instance */
const InstanceDemo = ({ SW }) => (
  <Fragment>
    {/* <h4>Control from outside component</h4> */}
    {/* <button className={'btn btn-secondary'} onClick={SW.previousStep}>Previous Step</button> */}
    {/* &nbsp; */}
    {/* <button className={'btn btn-secondary'} onClick={SW.nextStep}>Next Step</button> */}
    {/* &nbsp; */}
    {/* <button className={'btn btn-secondary'} onClick={() => SW.goToNamedStep('progress')}>Go to 'progress'</button> */}
  </Fragment>
)

/**
 * Stats Component - to illustrate the possible functions
 * Could be used for nav buttons or overview
 */

const dataForLeads = {
  test: "test",
}

const Stats = ({
  currentStep,
  firstStep,
  goToStep,
  lastStep,
  nextStep,
  previousStep,
  totalSteps,
  step,
}) => (
  <div>
    <hr />
    {step > 1 && (
      <CTAButton className="btn btn-default btn-block" onClick={previousStep}>
        Go Back
      </CTAButton>
    )}
    {step < totalSteps ? (
      <CTAButton className="btn btn-primary btn-block" onClick={nextStep}>
        Continue
      </CTAButton>
    ) : (
      <CTAButton className="btn btn-success btn-block" onClick={nextStep}>
        Finish
      </CTAButton>
    )}
    <hr />
    {/* <div style={{ fontSize: '21px', fontWeight: '200' }}> */}
    {/*     <h4>Other Functions</h4> */}
    {/*     <div>Current Step: {currentStep}</div> */}
    {/*     <div>Total Steps: {totalSteps}</div> */}
    {/*     <button className='btn btn-block btn-default' onClick={firstStep}>First Step</button> */}
    {/*     <button className='btn btn-block btn-default' onClick={lastStep}>Last Step</button> */}
    {/*     <button className='btn btn-block btn-default' onClick={() => goToStep(2)}>Go to Step 2</button> */}
    {/* </div> */}
  </div>
)

/** Steps */

const First = props => {
  const [emailError, setEmailError] = React.useState("")
  const update = e => {
    props.update(e.target.name, e.target.value)
    // console.log(e.target.name, e.target.value)

    obj[e.target.name] = e.target.value
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const _utm_medium = urlParams.get('utm_medium')
    const _utm_souce = urlParams.get('utm_souce')
    const _utm_campaign = urlParams.get('utm_campaign')
    const _utm_term = urlParams.get('utm_term')

    obj.utm_medium = _utm_medium
    obj.utm_source = _utm_souce
    obj.utm_campaign = _utm_campaign
    obj.utm_term = _utm_term
    console.log(obj)
  }

  const nextSteppers = () => {
    //     alert("test");
    //

    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (re.test(obj.email)) {
      props.nextStep()
    } else {
      console.log(email)

      setEmailError("Please set a valid email")
    }
  }

  return (
    <div>
      <SEO
        title="Own a Home"
        keywords="Vacation home Real estate investing, is real estate investing worth it, is real estate investing a business,how to start real estate investing"
      />
      <H1Text>Alpha Owner</H1Text>
      <PText>
        Own beautiful vacation rentals across the country without the hassle of
        acquiring, renovating, listing, or managing them (that'll be our job).
      </PText>
      <Hero2>
        <PText>First Name</PText>
        <CTAInput
          type="text"
          className="form-control"
          name="first_name"
          placeholder="First Name"
          onChange={update}
        />

        <PText>Last Name</PText>
        <CTAInput
          type="text"
          className="form-control"
          name="last_name"
          placeholder="Last Name"
          onChange={update}
        />
        <PText>Phone Number</PText>
        <CTAInput
          type="tel"        
          className="form-control"
          name="phoneNumber"
          placeholder="555-555-5555"
          onChange={update}
        />
        <PText>Email</PText>
        <CTAInput
          type="text"
          className="form-control"
          name="email"
          placeholder="Email"
          onChange={update}
          error={emailError}
        />
        <span style={{ color: "red" }}>{emailError}</span>
      </Hero2>
      <CTAButton onClick={nextSteppers}>Next Step</CTAButton>
    </div>
  )
}

const Second = ({ props, nextStep }) => {

  const validate = () => {
    if (window.confirm("Are you sure you want to go back?")) {
      // eslint-disable-line
      props.previousStep()
    }
  }
  const questions = [
    {
      questionText: "Are you more interested in a property for:",
      answerOptions: [
        {
          answerText: "Cash Flow",
          isCorrect: false,
          key: "a_use",
          value: "cash",
        },
        {
          answerText: "Second Home To Visit",
          isCorrect: false,
          key: "a_use",
          value: "second_home",
        },
        // { answerText: 'Paris', isCorrect: true },
        // { answerText: 'Dublin', isCorrect: false },
      ],
    },
    {
      questionText:
        "Are you ok with receiving discounted rates instead of free stays?",
      answerOptions: [
        {
          answerText: "Yes",
          isCorrect: false,
          key: "discounts",
          value: "discount_oks",
        },
        {
          answerText: "No",
          isCorrect: true,
          key: "discounts",
          value: "want_free",
        },
      ],
    },
    {
      questionText: "Which of these markets interests you the most?",
      answerOptions: [
        {
          answerText: "Orlando",
          isCorrect: false,
          key: "current_castle_recommendation",
          value: "orlando",
        },
        {
          answerText: "Las Vegas",
          isCorrect: true,
          key: "current_castle_recommendation",
          value: "las_vegas",
        },
        {
          answerText: "Tampa",
          isCorrect: true,
          key: "current_castle_recommendation",
          value: "tampa",
        },
        {
          answerText: "Other",
          isCorrect: true,
          key: "current_castle_recommendation",
          value: "other",
        },
      ],
    },
    {
      questionText: "How much do you have to invest",
      answerOptions: [
        {
          answerText: "$100,000+",
          isCorrect: true,
          key: "investment_amount",
          value: 100000,
        },
        {
          answerText: "$50,000",
          isCorrect: true,
          key: "investment_amount",
          value: 50000,
        },
        {
          answerText: "$25,000",
          isCorrect: true,
          key: "investment_amount",
          value: 25000,
        },
        {
          answerText: "$15,000",
          isCorrect: true,
          key: "investment_amount",
          value: 15000,
        },
        {
          answerText: "< $10,000",
          isCorrect: false,
          key: "investment_amount",
          value: 10000,
        },
      ],
    },

    // {
    //     questionText: 'Which markets are you interested in?',
    //     answerOptions: [
    //         { answerText: '< $10,000', isCorrect: false },
    //         { answerText: '$15,000', isCorrect: true },
    //         { answerText: '$25,000', isCorrect: true },
    //         { answerText: '$50,000', isCorrect: true },
    //         { answerText: '$100,000+', isCorrect: true },
    //     ],
    // },
  ]

  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [showScore, setShowScore] = useState(false)
  const [score, setScore] = useState(0)

  const nextSteppers = props => {
    nextStep()
  }

  const handleAnswerOptionClick = (isCorrect, answerText, key, value) => {
    // console.log(isCorrect)
    // console.log(answerText)

    // console.log(key)
    // console.log(value)

    obj[key] = value
    console.log(obj)
    // if (isCorrect) {
    //     setScore(score + 1);
    // }

    // Analytics().triggerData()

    const nextQuestion = currentQuestion + 1
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion)
    } else {
      nextSteppers()
    }
  }
  return (
    <div className="app">
      <>
        <div className="question-section">
          <div className="question-count">
            <span>Question {currentQuestion + 1}</span>/{questions.length}
          </div>
          <PText>{questions[currentQuestion].questionText}</PText>
        </div>
        <Hero3>
          {questions[currentQuestion].answerOptions.map(answerOption => (
            <CTAButton
              onClick={() =>
                handleAnswerOptionClick(
                  answerOption.isCorrect,
                  answerOption.answerText,
                  answerOption.key,
                  answerOption.value
                )
              }
            >
              {answerOption.answerText}
            </CTAButton>
          ))}
          {/* <button onClick={nextSteppers}>Next Step</button> */}
        </Hero3>
      </>
      {/* <Stats step={2} {...props} /> */}
    </div>
  )
}

class Box extends Component {
  // const Second = ({props,nextStep}) => {
  constructor(props) {
    super(props)
    this.state = {
      allChecked: false,
      list: [
        {
          id: 1,
          name: "Colorado",
          isChecked: false,
          img:
            "https://wpcdn.us-midwest-1.vip.tn-cloud.net/www.coloradohomesmag.com/content/uploads/data-import/37747f5e/Exterior-Entrepreneur-Laura-Loves-Denver-Home-Hentschel-Designs-Colorado-Homes-and-Lifestyles-magazine.jpg",
        },
        { id: 2, name: "Smoky Mountains", isChecked: false },
        { id: 3, name: "Phoenix", isChecked: false },
        { id: 4, name: "Orlando", isChecked: false },
        { id: 5, name: "Dallas", isChecked: false },
        { id: 6, name: "Cancun", isChecked: false },
        { id: 7, name: "Poconos", isChecked: false },
      ],
    }
  }

  handleChange = e => {
    let list = this.state.list
    let allChecked = this.state.allChecked
    if (e.target.value === "checkAll") {
      list.forEach(item => {
        item.isChecked = e.target.checked
        allChecked = e.target.checked
      })
    } else {
      list.find(item => item.name === e.target.name).isChecked =
        e.target.checked
    }
    this.setState({ list: list, allChecked: allChecked })

    let items = []

    for (let i = 0; i < list.length; i++) {
      if (list[i].isChecked) {
        // console.log(list[i])
        items.push(list[i].name)
        // break
      }
    }

    obj["intested_cities"] = items

    console.log(obj)
      
  }

  renderList = () => {
    return this.state.list.map(item => (
      <div>
        {/* <img src={item.img}/> */}
        <Input
          key={item.id}
          type="checkbox"
          name={item.name}
          value={item.name}
          checked={item.isChecked}
          onChange={this.handleChange}
        />
        <Label>{item.name}</Label>
      </div>
    ))
  }
  render() {
    return (
      <div>
        <H1Text>Where else would you want to own?</H1Text>
        <PText>Check all that apply</PText>
        {this.renderList()}
        <br />
        <Input
          type="checkbox"
          value="checkAll"
          checked={this.state.allChecked}
          onChange={this.handleChange}
        />{" "}
        <Label>Just Check All</Label>
        <Stats step={1} {...this.props} />
      </div>
    )
  }
}

const Progress = props => {
  const getData = () => {
    console.log("investment_amount")
    console.log(obj["investment_amount"])

    if (obj["intested_cities"] != null) {
      var cc_pop = localStorage.getItem("cc_alpha_lead")

      if (cc_pop != "true") {

        if (typeof window !== "undefined") {
          window.gtag("event", "alpha_lead_complete", {
            send_to: "UA-207899923-1",
            ...obj,
          })
        }

        var axios = require("axios")
        var data1 = JSON.stringify({
          email: "jimmy@cloudcastles.io",
          eventName: "internal_ping",
          dataFields: {
            obj: obj,
            // new_action: "alpha signup " + obj.email,
          },
        })

        var config = {
          method: "post",
          url: "https://api.iterable.com/api/events/track",
          headers: {
            "Content-Type": "application/json",
            //web token``
            "Api-Key": "dd373517425447ca8ab5d993282d17ab",
          },
          data: data1,
        }

        axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data))
            // send user to setup a meeting

          })
          .catch(function (error) {
            // alert()
            console.log(error.response.data)
          })


      } else {
      }

      localStorage.setItem("cc_alpha_lead", "true")

    }
  }

  const [state, updateState] = useState({
    isActiveClass: "",
    timeout: null,
  })

  useEffect(() => {
    const { timeout } = state
    getData()


    if (props.isActive && !timeout) {

      updateState({
        isActiveClass:
          "animation: grow @duration ease stripes 1s linear infinite rainbow @duration ease infinite",
        timeout: setTimeout(() => {
          props.nextStep()
        }, 3000),
      })
    } else if (!props.isActive && timeout) {
      clearTimeout(timeout)
      updateState({
        isActiveClass: "",
        timeout: null,
      })
    }
  })


  return (
    <div className={"padding-top: 25%"}>
      <PText>Automated Progress...</PText>
      <div
        className={`${"height: @progress-height overflow: hidden"} ${
          state.isActiveClass
        }`}
      >
        <div
          className={`${"@progress-height: 5px @duration: 3s"} progress-bar-striped`}
        />
      </div>
    </div>
  )
}

var CalendlyInvite = () => {
  return (
    <div style={{ height: "500px" }}>
      <iframe
        src="https://calendly.com/cloudcastles?primary_color=8bbef3"
        width="100%"
        height="100%"
        frameborder="0"
      ></iframe>
    </div>
  )
}

const Last = props => {
  const shareUrl = "https://cloudcastles.io"
  const title = "Cloud Castles - Own A Vacation Home"

  if(props.currentStep === 4){
    console.log("THEEND")  
    const email = obj["email"]
    const first_name = obj["first_name"]
    const last_name = obj["last_name"]
    const phoneNumber = obj["phoneNumber"]
    console.log(email)
    console.log(first_name)
    console.log(last_name)
    console.log(phoneNumber)
    window.location.href = `/setup-a-meeting?first_name=${first_name}&last_name=${last_name}&phoneNumber=${phoneNumber}&email=${email}`
  }
  

  if (true){    
  }

  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>Cloud Castles Alpha Singup</title>

        <script>
      
        
        </script>
      </Helmet>
      <div>
        <H1Text>Book Time To Speak To An Expert</H1Text>
        <PText>
          Speak with an expert on how we can get you a vacation home in no time.
        </PText>

        <hr />

        <CalendlyInvite />
        <PText>
          Share that you are buying a home and get a referral bonuses!
        </PText>

        <div className="Demo__some-network">
          <FacebookShareButton
            url={shareUrl}
            quote={title}
            className="Demo__some-network__share-button"
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <LinkedinShareButton
            url={shareUrl}
            className="Demo__some-network__share-button"
          >
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>

          <WhatsappShareButton
            url={shareUrl}
            title={title}
            separator=":: "
            className="Demo__some-network__share-button"
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
        </div>
      </div>
      {/* <Stats step={4} {...props} nextStep={submit} /> */}
    </div>
  )
}

export function sendToPartners(email, data) {
  var axios = require("axios")
  var data = JSON.stringify({
    email: email,
    dataFields: { source: "alpha_sign_up_web" },
  })

  // alert(email)
  // frontend iterable
  //  var config = {
  //    method: "post",
  //    url: "https://api.iterable.com/api/users/update",
  //    headers: {
  //      "Content-Type": "application/json",
  //      "Api-Key": "dd373517425447ca8ab5d993282d17ab",
  //    },
  //    data: data,
  //  }
  //
  //  axios(config)
  //    .then(function (response) {
  //      console.log(JSON.stringify(response.data))
  //      // setEmailSent("We'll send you info!")
  //      return response
  //    })
  //    .catch(function (error) {
  //      console.log(error)
  //      return error
  //    })
  //
}

const Body = styled.div`
  max-width: 100%;
  overflow-x: hidden !important;
  @media screen and (max-width: 667px) {
    body {
      overflow-x: hidden !important;
    }
    .container {
      max-width: 100% !important;
      overflow-x: hidden !important;
    }
  }
`

const Tbackground = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  @media only screen and (max-width: 425px) and (min-width: 100px) {
    overflow-x: hidden;
    position: absolute;
    display: none;
  }
`

const TopBackground1 = styled.img`
  overflow-x: hidden;
  position: absolute;
  left: -14.63%;
  right: 9.75%;
  top: -40.95%;
  bottom: 78.14%;
  z-index: -10;
  background: #8bbef3;
  border-radius: 333px;
  transform: matrix(0.98, -0.24, 0.2, 0.97, 0, 0);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
    overflow-x: hidden;
    position: absolute;
  }
  @media only screen and (max-width: 1399px) and (min-width: 426px) {
    overflow-x: hidden;
    position: absolute;
  }
  @media only screen and (max-width: 425px) and (min-width: 100px) {
    overflow-x: hidden;
    position: absolute;
    display: none;
  }
`

const TopBackground = styled.img`
  overflow-x: hidden;
  position: absolute;
  left: -14.63%;
  right: 9.75%;
  top: -4.95%;
  bottom: 78.14%;
  z-index: -10;
  background: #8bbef3;
  border-radius: 333px;
  transform: matrix(0.98, -0.24, 0.2, 0.97, 0, 0);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
    overflow-x: hidden;
    position: absolute;
  }
  @media only screen and (max-width: 1399px) and (min-width: 426px) {
    overflow-x: hidden;
    position: absolute;
  }
  @media only screen and (max-width: 425px) and (min-width: 100px) {
    overflow-x: hidden;
    position: absolute;
    display: none;

    left: -717.75px;
    top: -656.2px;
    background: #8bbef3;
    border-radius: 333px;
    transform: matrix(0.98, -0.24, 0.2, 0.97, 0, 0);
  }
`

const PText = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16.7436px;
  line-height: 151%;
  /* or 25px */
  margin: 30px auto;
  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }
  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }
  @media only screen and (max-width: 425px) and (min-width: 100px) {
    font-size: 12.7436px;
    line-height: 101%;
    font-weight: 400;
    margin: 30px 0px;
  }
`

const Logo = styled.img`
  max-width: 100%;
  /*padding: 0px 0px 0px 30px;*/
  align-items: center;
  justify-content: center;
  position: relative;
  @media only screen and (max-width: 40em) {
  }
`

const TopBackground2 = styled.img`
  overflow-x: hidden;
  position: absolute;
  left: -1.72%;
  right: 32.8%;
  top: -16.64%;
  bottom: 81.18%;
  z-index: -10;
  background: #8bbef3;
  opacity: 0.05;
  border-radius: 333px;
  transform: matrix(1, -0.1, 0.08, 1, 0, 0);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
    overflow-x: hidden;
    position: absolute;
  }
  @media only screen and (max-width: 1399px) and (min-width: 426px) {
    overflow-x: hidden;
    position: absolute;
  }
  @media only screen and (max-width: 425px) and (min-width: 100px) {
    overflow-x: hidden;
    position: absolute;
  }
`

const H1Text = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  font-size: 35.8802px;
  line-height: 119%;
  /* or 43px */
  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  font-feature-settings: "liga" off;
  margin: 30px auto;
  padding: auto;
  @media only screen and (max-width: 600px) and (min-width: 100px) {
  }
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }
  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }
  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const Hero = styled.div`
  width: 85%;
  overflow-x: hidden;
  display: flex;
  z-index: 10;
  flex-direction: column;
  justify-content: center;
  margin: 10px auto;
  align-items: center;
  background-color: #fff;
  margin: 30px auto;
  /*width: 200px;*/
  padding: 50px;
  text-align: center;
  /* border-radius */
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  /* box-shadow */
  -webkit-box-shadow: rgba(0, 0, 0, 0.8) 0px 0 10px;
  -moz-box-shadow: rgba(0, 0, 0, 0.8) 0 0 10px;
  box-shadow: rgba(0, 0, 0, 0.8) 0 0 10px;
  @media only screen and (max-width: 625px) and (min-width: 100px) {
    max-width: 90%;
    /*flex-direction: column-reverse;
    margin: -70px 50px 50px -20px;*/
  }
`
const Hero2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 0;
`

const Hero3 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const CTAButton = styled.button`
  padding: 10.1461px 36.7772px;
  cursor: pointer;
  margin: 20px 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14.1461px;
  line-height: 24px;
  color: #ffffff;
  background: #8578fc;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;
  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  max-width: 100%;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }
  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }
  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const Label = styled.label`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16.7436px;
  line-height: 151%;
  /* or 25px */
  /*margin: 30px auto;*/
  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }
  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }
  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const Input = styled.input`
  height: 15px;
  width: 15px;
`

const CTAInput = styled.input`
  display: flex;
  /*padding: 16.1461px 30.7772px;*/
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  /*font-size: 16.1461px;*/
  line-height: 24px;
  color: black;
  border: 0.897006px solid #8578fc;
  box-sizing: border-box;
  filter: drop-shadow(0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26));
  border-radius: 40.3653px;
  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }
  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }
  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`
