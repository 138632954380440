import { createGlobalStyle } from 'styled-components';

import NameOfYourFontWoff from './poppins-v15-latin-regular.woff';
import NameOfYourFontWoff2 from './poppins-v15-latin-regular.woff2';

export default createGlobalStyle`
    @font-face {
        font-family: 'Poppins';
        src: local('Poppins'), local('Poppins'),
        url(${NameOfYourFontWoff2}) format('woff2'),
        url(${NameOfYourFontWoff}) format('woff');
        font-weight: 300;
        font-style: normal;
    }
`;